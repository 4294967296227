import { render, staticRenderFns } from "./Relvado.vue?vue&type=template&id=6919a20a&scoped=true&"
import script from "./Relvado.vue?vue&type=script&lang=js&"
export * from "./Relvado.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6919a20a",
  null
  
)

export default component.exports